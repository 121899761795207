<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
      <v-row>
        <v-col cols="12" md="7">
          <v-select
            label="Select Status"
            v-model="Selstatus"
            :items="Status"
            item-text="status_named"
            item-value="status_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnsearch" class="ml-1">
            <v-icon left>mdi-reload</v-icon>Load Data</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-1 mb-2" flat>
      <v-data-table
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
        style="border-radius: 0px"
      >
        <!-- @click="BtnApprove(index)" -->
        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="4" class="pa-5 <v-flex xs1> </v-flex>">
              <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item="{ item }">
          <tr
            @click="BtnApprove(item.sr_no, item.status_id, item.application_id)"
          >
            <!-- <td>
            <v-checkbox
              v-model="chkselected"
              :value="item.application_id"
              @click="chkclick"
            ></v-checkbox>
          </td> -->
            <td style="text-align: left; width: 50px">
              {{ item.app_id }}
            </td>

            <td style="text-align: left; width: 65px">
              <img
                :src="$apiPath + item.photo"
                height="60px"
                width="60px"
                style="border-radius: 10px; border: 1px solid #ccc"
                type="button"
              />
            </td>

            <td>{{ item.firstName }}</td>
            <td>{{ item.mediaName }}</td>
            <td>{{ item.role_name }}</td>
            <td>{{ item.print_text }}</td>
            <td>{{ item.status_named }}</td>
            <!-- <td>{{ item.reprintCount }}</td> -->
            <td>{{ item.mediaType_name }}</td>
            <td>{{ item.card_name }}</td>
            <td>{{ item.reason_name }}</td>

            <!-- <td>{{ item.mediaName }}</td>
             <td>{{ item.role_name }}</td>
            <td>{{ item.print_text }}</td>
           <td>{{ item.status_named }}</td>
           <td>{{ item.reprintCount }}</td> -->
          </tr>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="isViewAppOpened"
      fullscreen
      style="z-index: 1000; width: 1000px"
    >
      <v-card flat style="background-color: rgb(233 233 233)">
        <v-toolbar dark :color="getColorByStatus(cApp.status_named)" dense style="position:sticky;top:0px;z-index:1001;">
          <v-toolbar-title
            >Application-ID : {{ cApp.app_id }} - {{cApp.status_named}}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
           

            <v-btn icon dark @click="btncloseViewApp">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- :appreasonlist="appreasonlist" -->
 <v-card-text class="pa-0">
         <ViewApplicationLFW
           v-if="this.$store.state.eventInfo.media_template_id=='1'"
            :appData="cApp"
            :appstatus="appstatus"
            :appreasonlist="appreasonlist"
          >
          </ViewApplicationLFW>

       
          <ViewApplication
            v-if="this.$store.state.eventInfo.media_template_id=='2'"
            :appData="cApp"
            :appstatus="appstatus"
            :appreasonlist="appreasonlist"
          >
          </ViewApplication>

          
          <ViewApplicationICC
            v-if="this.$store.state.eventInfo.media_template_id=='5'"
            :appData="cApp"
            :appstatus="appstatus"
            :appreasonlist="appreasonlist"
          >
          </ViewApplicationICC>

          <ViewApplicationILT
            v-if="this.$store.state.eventInfo.media_template_id=='7'"
            :appData="cApp"
            :appstatus="appstatus"
            :appreasonlist="appreasonlist"
          >
          </ViewApplicationILT>

           <ViewApplicationPklWI
            v-if="this.$store.state.eventInfo.media_template_id=='6'"
            :appData="cApp"
            :appstatus="appstatus"
            :appreasonlist="appreasonlist"
          >
          </ViewApplicationPklWI>
          
        </v-card-text>
        <v-footer
          class="mt-1 pa-3"
          style="
            bottom: 0px;
            left: 0px;
            z-index: 1001;
            position: fixed;
            width: 100%;
          "
        >
          <v-btn
            medium
            color="primary"
            :disabled="btnpredisable"
            @click="appPrevious"
            class="ml-1"
          >
            Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            medium
            color="primary"
            :disabled="btnnextdisable"
            @click="appNext"
            class="ml-1"
          >
            Next
          </v-btn>
        </v-footer>
      </v-card>

      <v-overlay :absolute="absolute" :value="overlay" style="z-index: 9000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>

    <v-snackbar v-model="snackbar" top :color="snackbarcolor">
      {{ snackbartext }}
    </v-snackbar>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
import ViewApplication from "../../components/Media/ViewApplication.vue";
import ViewApplicationLFW from "../../components/Media/ViewApplicationLFW.vue";
import ViewApplicationICC from "../../components/Media/ViewApplicationICC.vue";
import ViewApplicationILT from "../../components/Media/ViewApplicationILT.vue";
import ViewApplicationPklWI from "../../components/Media/ViewApplicationPklWI.vue"

export default {
  components: {
    ViewApplication,ViewApplicationLFW,ViewApplicationICC,ViewApplicationPklWI,ViewApplicationILT
  },
  data() {
    return {
      btnnextdisable: true,
      btnpredisable: true,
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: 0,
      absolute: true,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "",
      Status: [
{
          status_id: 0,
          status_name: "All",
          status: "O",
          status_named: "All",
        },

        {
          status_id: 8,
          status_name: "Pending",
          status: "O",
          status_named: "Pending",
        },
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },
        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },

        {
          status_id: 7,
          status_name: "Print",
          status: "O",
          status_named: "Printed",
        },

        
      ],
      SelstatusForUpade: "",
      StatusForUpdate: [
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },

        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },

        {
          status_id: 8,
          status_name: "Print",
          status: "O",
          status_named: "Printed",
        },
      ],

      SelReasonForUpdate: "",
      ResonForUpdate: [],
      appreasonlist: [],
      MediaVenueForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        no_of_application: "",
        deadline: "",
        companytype_id: [],
        Vendor_ID_Update: "",
        uflag: "0",
        FlagCompanyName: "",
        FlagEmail: "",
      },

      postBodyBulk: {
        event_id: "",
        venue_id: "",
        deadline: "",
      },
      postBulkUpdate: {
        application_id: [],
        event_id: "",
        venue_id: "",
        vendor_id: "",
        status_id: "",
        reason_id: "",
        card_id: "",
      },
      headers: [
        {
          text: "App ID",
          align: "left",
          value: "app_id",
          class: "blue lighten-5",
          sortable: false,
        },

        {
          text: "Photo",
          value: "",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Name",
          value: "firstName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Publication",
          value: "mediaName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Designation",
          value: "role_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Zone",
          value: "print_text",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "App Status",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },

        // {
        //   text: "Reprint Count",
        //   value: "reprintCount",
        //   align: "left",
        //   class: "blue lighten-5",
        // },

        {
          text: "Media Type",
          value: "mediaType_name",
          align: "left",
          class: "blue lighten-5",
        },
         {
          text: "Card",
          value: "card_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Reason",
          value: "reason_name",
          align: "left",
          class: "blue lighten-5",
        },

        // {
        //   text: "Card",
        //   value: "card_name",
        //   align: "left",
        //   class: "blue lighten-5",
        // },
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    // sessionStorage.display_event_name = "IPL T20 2021";
    // sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Application Manager");
    this.$store.commit("setPageTitleIcon", "mdi-chart-box-plus-outline");

   // this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    // this.bindStatus();
    //  this.bindCartype(this.$store.state.eventInfo.EventId);

    //  this.bindReson("1");
  },

  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    btncloseViewApp: function () {
      this.isViewAppOpened = false;
      this.Reload(this.$store.state.eventInfo.EventId, this.Selstatus,this.$store.state.eventInfo.VenueId);
      
      
    },

    getreson: function () {
      // alert(this.SelstatusForUpade);
      this.bindReson(this.SelstatusForUpade);
      this.strcheckatatus = this.SelstatusForUpade;
    },
    chkall: function () {
      alert("test");
      // this.bindReson(this.SelstatusForUpade);
    },

    binddata: async function (eventid, appid) {
      this.overlay = 1;
      await this.$axios
        .get("Media/Details/" + eventid + "/" + appid)
        .then((res) => {
          console.log("BIKI TEST");
          console.log(res.data.result);
          this.cApp = res.data.result;
          this.cApp.dob = this.cApp.dob.slice(0, 10);
          this.overlay = 0;
          this.bindReson(this.cApp.status_id);
        });
    },

    BtnApprove: function (SNo) {
      //this.cApp=[];

      this.cAppIndex = SNo - 1;
      this.binddata(
        this.$store.state.eventInfo.EventId,
        this.desserts[this.cAppIndex].application_id
      );

      sessionStorage.COMPANYID = this.SelCompany;

      this.isViewAppOpened = true;
      this.btnnextdiaable();
      this.btnprediaable();
    },

    // BtnApprove: function (ID, status_ID) {
    //   this.cAppIndex = ID - 1;
    //   this.cApp = this.desserts[this.cAppIndex];
    //   this.cApp.dob = this.cApp.dob.slice(0, 10);
    //   this.appstatus = status_ID;
    //   sessionStorage.COMPANYID = this.SelCompany;
    //   this.isViewAppOpened = true;

    // },

    btn_reload: function () {
      // this.overlay = true;
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );
      // this.overlay = false;
    },

    bindReson: async function (StatusID) {
     //this.appreasonlist=[];
      await this.$axios
        .get("Reason/GetByStatusID/" + this.$store.state.eventInfo.EventId + "/" + StatusID)
        .then((res) => {
          //console.log("rsg test");
         // console.log(res.data.result);
          this.appreasonlist = res.data.result;
        });
    },

    //  bindmediaVenue: function (eventid) {
    //   axios
    //     .get(this.$apiPath + "Media/mediaVenue/" + eventid, {
    //       headers: { Appkey: this.$aKey },
    //     })
    //     .then((res) => {
    //       console.log(res.data.result);
    //       this.MediaVenueForUpdate = res.data.result;
    //     });
    // },

    btnsearch: function () {
      //alert(this.overlay);
      this.TDselected = [];
      this.desserts = [];
      // alert(this.Selstatus.length);
      // alert(this.desserts.length);
      if (this.Selstatus.length <= 0) {
        // this.overlay = 0;

        this.showSnackbar("error", "Select Card !");
      } else {
        this.desserts = [];
        this.chkselected = [];
        this.appstatus = this.Selstatus;
        this.bindReson(this.Selstatus);
        this.Reload(this.$store.state.eventInfo.EventId, this.Selstatus,this.$store.state.eventInfo.VenueId);

        // this.bindmediaVenue(this.$store.state.eventInfo.EventId);
      }
    },
    btnnextdiaable: function () {
      if (this.cAppIndex < this.desserts.length - 1) {
        this.btnnextdisable = false;
        this.btnpredisable = false;
      } else {
        this.btnnextdisable = true;
        this.btnpredisable = false;
      }
    },

    btnprediaable: function () {
      if (this.cAppIndex > 0) {
        this.btnpredisable = false;
        this.btnnextdisable = false;
      } else {
        this.btnpredisable = true;
        this.btnnextdisable = false;
      }
    },

    appNext: function () {
      if (this.cAppIndex < this.desserts.length - 1) {
        this.cAppIndex = this.cAppIndex + 1;

        this.binddata(
          this.$store.state.eventInfo.EventId,
          this.desserts[this.cAppIndex].application_id
        );
        //alert(this.cApp.application_id);
        this.bindReson(this.cApp.status_id);
      }

      this.btnnextdiaable();
    },
    appPrevious: function () {
      if (this.cAppIndex > 0) {
        this.cAppIndex = this.cAppIndex - 1;

        this.binddata(
          this.$store.state.eventInfo.EventId,
          this.desserts[this.cAppIndex].application_id
        );
        this.bindReson(this.cApp.status_id);
      }
      this.btnprediaable();
    },

    bindCartype: async function (event_id) {
      await this.$axios
        .get("Cards/GetByEventId/" + event_id)
        .then((res) => {
        //  console.log(res.data.result);
          this.CardTypeForUpdate = res.data.result;
        });
    },

    //  bindStatus: function () {
    //    axios
    //     .get(this.$apiPath + "Status", {
    //        headers: { Appkey: this.$aKey },
    //      })
    //      .then((res) => {
    //        this.Status = res.data.result;
    //        this.StatusForUpdate = res.data.result;
    //       console.log(this.StatusForUpdate);
    //     });
    //  },

    //  bindReson: function (StatusID) {
    //    axios
    //     .get(this.$apiPath + "Reason/GetByStatusID/" + StatusID, {
    //        headers: { Appkey: this.$aKey },
    //      })
    //      .then((res) => {
    //        this.ResonForUpdate = res.data.result;
    //      });
    //  },

    Reload: async function (event_id, status,venue_id) {
      this.overlay = 1;
      this.loadingstatus = true;
      await this.$axios
        .get(
          "Media/GetByAppstatus/" + event_id + "/" + status + "/" + venue_id
        )
        .then((res) => {
          console.log(res.data.result);
          this.desserts = res.data.result;
          this.overlay = 0;
          this.loadingstatus = false;
          this.TDselected = [];
          this.postBulkUpdate.application_id = [];
        })
        .catch()
        .finally();
    },

getColorByStatus(status){
      if(status=='Submitted'){
        return 'blue';
      }
      else if(status=='Approved'){
        return 'green';
      }
      else if(status=='Amended'){
        return 'orange';
      }
      else if(status=='Cancelled'){
        return 'red';
      }
      else if(status=='Rejected'){
        return 'red';
      }
      else if(status=='Printed'){
        return 'teal';
      }
    },

    New() {
      this.EntryMode = "New Vendor";
      //  this.postBody.event_name = "";
      this.CleareData();
      this.sheet = true;
    },

    close() {
      this.sheet = false;
      //  this.Reload();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style>
</style>
