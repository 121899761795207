<template>
  <div class="pb-15">
    <v-container>
      <v-tabs
        background-color="#4f87b3"
        dark
        v-model="tabs"
        fixed-tabs
        style="position: sticky; top: 42px; z-index: 1005; width: 100%"
      >
        <v-tab>
          <v-icon left> mdi-account </v-icon>
          Personal
        </v-tab>
        <v-tab>
          <v-icon left> mdi-lock </v-icon>
          Publication
        </v-tab>
         <!-- <v-tab>
          <v-icon left> mdi-access-point </v-icon>
          Job
        </v-tab>

        <v-tab>
          <v-icon left> mdi-access-point </v-icon>
          Additional
        </v-tab> -->
      </v-tabs>

      <v-row>
        <v-col cols="12" md="8">
          <v-card flat style="background-color: transparent" min-height="700">
            <v-tabs-items v-model="tabs" style="background-color: transparent">
              <v-tab-item>
                <v-card flat class="mt-3">
                  <div class="cardHeader">Personal Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="12" md="6">
                        <FieldValue
                          Text="First Name"
                          :value="appData.firstName"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="12" md="3">
                        <FieldValue
                          Text="Last Name"
                          :value="appData.lastName"
                        ></FieldValue>
                      </v-col>

                       <!-- <v-col cols="12" md="3">
                        <FieldValue
                          Text="Father/Guardian Name"
                          :value="appData.fatherName"
                        ></FieldValue>
                      </v-col> -->
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>

                    <v-row>
                      <v-col cols="12" md="6">
                        <FieldValue
                          Text="Gender"
                          :value="appData.gender"
                        ></FieldValue>
                      </v-col>
                      <v-col cols="12" md="3">
                        <FieldValue
                          Text="DOB"
                          :value="appData.dob"
                        ></FieldValue>
                      </v-col>
                      <v-col cols="12" md="3">
                        <FieldValue
                          Text="Nationality"
                          :value="appData.nationality"
                        ></FieldValue>
                      </v-col>
                    </v-row>

                    <!-- <v-divider class="mt-2 mb-2"></v-divider> -->
                    <!-- <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="ID Proof"
                          :value="appData.idProofType"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="ID Proof No"
                          :value="appData.idProofNumber"
                        ></FieldValue>
                      </v-col>
                    </v-row> -->
                  </div>
                </v-card>

                <!-- <v-card flat class="mt-3">
                  <div class="cardHeader">Address Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Address Line 1"
                          :value="appData.addLine1"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Line 2"
                          :value="appData.addLine2"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Town/City"
                          :value="appData.city"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Parish/State/Country"
                          :value="appData.state"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Country"
                          :value="appData.country"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Postal Zip code"
                          :value="appData.zipCode"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                  </div>
                </v-card> -->

                <v-card flat class="mt-3">
                  <div class="cardHeader">Contact Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Mobile No"
                          :value="appData.ext + ' ' + appData.mobile"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Email ID"
                          :value="appData.email"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>


<!-- <v-card flat class="mt-3">
                  <div class="cardHeader">Covid-19 Vaccination Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="12">
                        <FieldValue
                          Text="Have you been vaccinated ?"
                          :value="appData.isVaccinated"
                        ></FieldValue>
                      </v-col>

                     
                    </v-row>
                  </div>
                </v-card> -->

              </v-tab-item>
              <v-tab-item>
                <v-card flat class="mt-3">
                  <div class="cardHeader">Publication Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Name of Media House"
                          :value="appData.mediaName"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Publication Website"
                          :value="appData.mwdiaWebsite"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>

                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Media Category"
                          :value="appData.mediaType_name"
                        ></FieldValue>
                      </v-col>
                      <v-col cols="3">
                        <FieldValue
                          Text="Type of Orgnization"
                          :value="appData.organisationType_name"
                        ></FieldValue>
                      </v-col>

<v-col cols="3">
                        <FieldValue
                          Text="Designation"
                          :value="appData.role_name"
                        ></FieldValue>
                      </v-col>

                    </v-row>
                  </div>
                </v-card>
<!-- 
                <v-card flat class="mt-3">
                  <div class="cardHeader">Publication Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Addres Line 1"
                          :value="appData.pAddLine1"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Line 2"
                          :value="appData.pAddLine2"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Town/City"
                          :value="appData.pCity"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Parish/State/Country"
                          :value="appData.pState"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Country"
                          :value="appData.pCountry"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Postal Zip code"
                          :value="appData.pZipCode"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                  </div>
                </v-card> -->

                <v-card flat>
                  <div class="cardHeader">Authorisation Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Name"
                          :value="appData.authName"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Role"
                          :value="appData.authRole"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Mobile No"
                          :value="appData.authMobExt + ' ' + appData.authMobile"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="6">
                        <FieldValue
                          Text="Email ID"
                          :value="appData.authEmail"
                        ></FieldValue>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat min-height="700px" class="mt-3">
                  <div class="cardHeader">Job Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Have you covered any ICC event previously?"
                          :value="appData.isPastEvent"
                        ></FieldValue>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="appData.pastevent"
                          outlined
                          :items="Pastevent"
                          item-text="pe_name"
                          item-value="pe_id"
                          label="Event which you have previously covered"
                          hide-details
                          attach
                          chips
                          small-chips
                          multiple
                          deletable-chips
                          disabled
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <FieldValue
                          Text="Your Role"
                          :value="appData.role_name"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="12">
                        <!-- <FieldValue
                          Text="Prefered City for accreditation collection"
                          :Value="appData.preferedCity"
                        ></FieldValue> -->

                        <v-select
                          label="Selected Prefered City"
                          v-model="appData.preferedCity"
                          :items="MediaVenueForUpdate"
                          item-text="venue_name"
                          item-value="venue_id"
                          dense
                          outlined
                          hide-details
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="appData.selectedMatchs"
                          outlined
                          :items="matchSchedule"
                          item-text="match_name"
                          item-value="s_id"
                          label="Selected Matches"
                          hide-details
                          attach
                          chips
                          small-chips
                          multiple
                          deletable-chips
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat min-height="900px" class="mt-3">
                  <div class="cardHeader">Additional Details</div>

                  <div class="pa-3">
                    <v-row>
                      <v-col cols="6">
                        <FieldValue
                          Text="Are you presently available at the host nation ?"
                          :value="appData.fromHostNation"
                        ></FieldValue>
                      </v-col>
                    </v-row>

                    <div v-if="appData.fromHostNation == 'No'">
                      <v-row>
                        <v-col cols="3">
                          <FieldValue
                            Text="Departure Port"
                            :value="appData.departPort"
                          ></FieldValue>
                        </v-col>

                        <v-col cols="3">
                          <FieldValue
                            Text="Departure Date"
                            :value="appData.departDate"
                          ></FieldValue>
                        </v-col>
                        <v-col cols="3">
                          <FieldValue
                            Text="Departure Time"
                            :value="appData.departTime"
                          ></FieldValue>
                        </v-col>
                        <v-col cols="3">
                          <FieldValue
                            Text="Departure Flight"
                            :value="appData.departFlight"
                          ></FieldValue>
                        </v-col>

                        <v-col cols="3">
                          <FieldValue
                            Text="Arriving Port"
                            :value="appData.arrivePort"
                          ></FieldValue>
                        </v-col>

                        <v-col cols="3">
                          <FieldValue
                            Text="Arriving Date"
                            :value="appData.arriveDate"
                          ></FieldValue>
                        </v-col>
                        <v-col cols="3">
                          <FieldValue
                            Text="Arriving Time"
                            :value="appData.arriveTime"
                          ></FieldValue>
                        </v-col>
                        <v-col cols="3">
                          <FieldValue
                            Text="Arriving Flight"
                            :value="appData.arriveFlight"
                          ></FieldValue>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <div class="cardHeader">Visa Details</div>
                        </v-col>
                        <v-col cols="6">
                          <FieldValue
                            Text="Visa Number"
                            :value="appData.visaNo"
                          ></FieldValue>
                        </v-col>
                        <v-col cols="6">
                          <FieldValue
                            Text="Visa Valid Till"
                            :value="appData.visaValidTill"
                          ></FieldValue>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" class="pa-0">
                          <div class="cardHeader">Accommodation Details</div>
                        </v-col>
                        <v-col cols="6">
                          <FieldValue
                            Text="Line 1"
                            :value="appData.accAddLine1"
                          ></FieldValue>
                        </v-col>
                        <v-col cols="6">
                          <FieldValue
                            Text="Line 2"
                            :value="appData.accAddLine2"
                          ></FieldValue>
                        </v-col>

                        <v-col cols="3">
                          <FieldValue
                            Text="City"
                            :value="appData.accCity"
                          ></FieldValue>
                        </v-col>

                        <v-col cols="3">
                          <FieldValue
                            Text="Parish/State/Country"
                            :value="appData.accState"
                          ></FieldValue>
                        </v-col>

                        <v-col cols="3">
                          <FieldValue
                            Text="Country"
                            :value="appData.accCountry"
                          ></FieldValue>
                        </v-col>

                        <v-col cols="3">
                          <FieldValue
                            Text="Postal Zip"
                            :value="appData.accZipCode"
                          ></FieldValue>
                        </v-col>
                      </v-row>
                    </div>

                    <v-row>
                      <v-col cols="12" class="pa-0">
                        <div class="cardHeader">Covid 19 Assesment</div>
                      </v-col>
                      <v-col cols="12">
                        <FieldValue
                          Text="Do you have any pre-existing medical condition?"
                          :value="appData.anyMedicalCondition"
                        ></FieldValue>
                      </v-col>

                      <v-col
                        cols="12"
                        v-if="appData.anyMedicalCondition == 'Yes'"
                      >
                        <FieldValue
                          Text="Any Medical Condition"
                          :value="appData.medicalCondition"
                        ></FieldValue>
                      </v-col>

                      <v-col cols="12">
                        <FieldValue
                          Text="Have you been vaccinated?"
                          :value="appData.isVaccinated"
                        ></FieldValue>
                      </v-col>
                    </v-row>

                    <!-- <div v-if="appData.isVaccinated == 'Yes'">
                      <v-row>
                         <v-col cols="3">
                          <FieldValue
                            Text="Vaccination Taken"
                            :value="appData.vaccineTaken"
                          ></FieldValue>
                        </v-col> 

                         <v-col cols="3">
                          <FieldValue
                            Text="Country Of Issue"
                            :value="appData.vaccineCountry"
                          ></FieldValue>
                        </v-col> 

                         <v-col cols="3">
                          <FieldValue
                            Text="Daye of First Dose"
                            :value="appData.firstDose"
                          ></FieldValue>
                        </v-col> 

                        <v-col cols="3">
                          <FieldValue
                            Text="Daye of Second Dose"
                            :value="appData.secondDose"
                          ></FieldValue>
                        </v-col> 
                      </v-row>
                    </div> -->
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat class="mt-3">
            <!-- <div class="cardHeader">Headshot Picture</div>
            <div class="pa-3 text-lg-center">
              <viewer :images="[this.$apiPath + appData.photo]">
                <img
                  :src="this.$apiPath + appData.photo"
                  :key="src"
                  class="imgId"
                />
              </viewer>
            </div>

           

            <br />
            <v-btn v-if="appData.status_name == 'Submit'" @click="btncrop"
              >Crop</v-btn
            > -->


 <div class="cardHeader">Headshot Picture</div>
            <div class="pa-3 text-lg-center">
              <viewer :images="[this.$apiPath + appData.photo]">
                <img
                  :src="this.$apiPath + appData.photo"
                  :key="src"
                  class="imgId"
                />
              </viewer>
                <v-btn text color="primary" class="pa-3 text-lg-center" v-if="appData.status_named != 'Approved' && appData.status_named != 'Printed'" @click="btncrop"
              >
               <v-icon left>mdi-crop</v-icon>
              CROP
             
              </v-btn>
            </div>

          </v-card>

          <!-- <v-card flat class="mt-3">
            <div class="cardHeader">ID Proof</div>
            <div class="pa-3">
              <v-row>
                <v-col cols="6">
                  <viewer :images="[this.$apiPath + appData.photoIdFront]">
                    <img
                      :src="this.$apiPath + appData.photoIdFront"
                      :key="src"
                      class="imgId"
                      width="100%"
                    />
                  </viewer>
                </v-col>
                <v-col cols="6" v-if="appData.photoIDBack">
                  <viewer :images="[this.$apiPath + appData.photoIDBack]">
                    <img
                      :src="this.$apiPath + appData.photoIDBack"
                      :key="src"
                      class="imgId"
                      width="100%"
                    />
                  </viewer>
                </v-col>
              </v-row>
            </div>
            <br />
          </v-card> -->


          <v-card flat class="mt-3">
            <div class="cardHeader">Editor's Letter</div>
            <div class="pa-3">
              <v-card flat class="mt-6">
                <div class="text-center pa-2">
                  <v-btn
                    outlined
                    color="primary"
                   
                    @click="btnvieweditorletter"
                   
                    >View Editor Letter
                    <v-icon right>mdi-book-search-outline</v-icon></v-btn
                  >
                </div>
              </v-card>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- v-if="appstatus == '2'" -->
    <div
      v-if="appData.status_id != '7'"
      style="
        z-index: 1300;
        position: fixed;
        bottom: 0px;
        right: 400px;
        max-width: 700px;
        margin: auto;
      "
      class="mt-10"
    >
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="4">
          <v-select
            label="Select Status"
            v-model="appData.status_id"
            :items="StatusForUpdate"
            item-text="status_name"
            item-value="status_id"
            dense
            outlined
            @change="getreson"
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="6" v-if="appData.status_id != 3">
          <v-select
            label="Select Reason"
            v-model="appData.reason_id"
            :items="appreasonlist"
            item-text="reason_name"
            item-value="reason_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" v-if="appData.status_id == 3">
          <v-select
            label="Select Card Type"
            v-model="appData.mediaType"
            :items="CardTypeForUpdate"
            item-text="card_name"
            item-value="card_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>

        <v-col cols="12" md="2">
          <v-btn medium color="primary" @click="btnupdate" class="fullwidth">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-snackbar
      v-model="snackbar"
      top
      :color="snackbarcolor"
      style="z-index: 1016"
      timeout="1000"
    >
      {{ snackbartext }}
      <v-btn text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-dialog v-model="modelPhoto" max-width="500" persistent>
      <v-card flat class="pa-2">
        <div class="content">
          <section class="cropper-area">
            <div class="img-cropper">
              <VueCropper
                ref="cropper"
                :aspect-ratio="11 / 12"
                :src="editpath"
                :key="editpath"
                style="max-height: 400px"
                id="yourImageTag"
              />
            </div>
          </section>
          <div class="pt-3 text-center">
            <div
              style="
                border: 1px solid #e6e6e6;
                background-color: #fafafa;
                border-radius: 8px;
                padding: 3px 0px;
              "
            >
              <v-btn icon small @click="imageOpt(0)" class="mr-3">
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(1)" class="mr-3">
                <v-icon>mdi-magnify-minus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(2)" class="mr-3">
                <v-icon>mdi-flip-horizontal</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(3)" class="mr-3">
                <v-icon>mdi-rotate-right</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(4)" class="mr-3">
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(5)" class="mr-3">
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(6)" class="mr-3">
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(7)" class="mr-3">
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(8)">
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-card-actions class="pt-4 text-center">
          <v-btn color="error" outlined @click="close">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="closeDialog">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


 <v-dialog max-width="600" scrollable v-model="dialogeditor" :fullscreen="fullscreen1">
      <v-card flat>
        <v-toolbar flat>
          <div style="font-weight: 600">Editor letter</div>
          <v-spacer></v-spacer>
           <v-btn right icon color="red" @click="makefulldilog"
            ><v-icon>{{maxiconname}}</v-icon></v-btn
          >
          <v-btn right icon color="red" @click="dialogeditor = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text v-if="this.appData.editorLetter!=null"  style="height: 600px">
           <pdf
            v-if="this.appData.editorLetter.substring(this.appData.editorLetter.length-3)=='pdf'"
            :src="this.$apiPath + appData.editorLetter"
            :key="this.$apiPath + appData.editorLetter"
          >
          </pdf>
          <v-img
         v-else
            :src="this.$apiPath + appData.editorLetter"
            :key="this.$apiPath + appData.editorLetter"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

<v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="100"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import FieldValue from "./FieldValue";

import Vue from "vue";
//import axios from "axios";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import pdf from "vue-pdf";
//import moment from 'moment'
Vue.use(Viewer);
export default {
  props: ["appData", "appstatus", "appreasonlist"],
  components: { VueCropper, FieldValue ,pdf},
  data: () => ({
    maxiconname:"mdi-fullscreen",
    overlay:false,
    dialogeditor:false,
    fullscreen1:false,
    modelPhoto: false,
    picpathbackup: "",
    picpath: "",
    editpath: "",
    tabs: 0,
    snackbar: false,
    snackbartext: "",
    snackbarcolor: "primary",
    images: ["https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"],
    Pastevent: [],
    matchSchedule: [],
    SelStand: "",
    MediaVenueForUpdate: [],
    mediaphoto: {
      event_id: "",
      application_id: "",
      firstName: "",
      photo: "",
    },

    StatusForUpdate: [
      {
        status_id: 3,
        status_name: "Approve",
        status: "O",
        status_named: "Approved",
      },

      {
        status_id: 4,
        status_name: "Amend",
        status: "O",
        status_named: "Amended",
      },

      {
        status_id: 6,
        status_name: "Reject",
        status: "O",
        status_named: "Rejected",
      },
    ],
    ResonForUpdate: [],
    CardTypeForUpdate: [],
    src: "",
    Status: "",
    Reson: "",
    PostData: {
      event_id: "",
      status_id: "",
      reason_id: "",
      preferedCity: "",
      card_id: "",
      eaid:0,
      // azone:[],
      selectedMatch: [],
      roleForEvent:0
    },
  }),

  watch: {
    "appData.application_id": function () {
      this.tabs = 0;
    },
  },

  mounted() {
    console.log(this.appData);
    // alert(this.appstatus);
    sessionStorage.EVENTID = "1";
    sessionStorage.VENUEID = "1";
    sessionStorage.VENDORID = "1";
    this.bindPastevent();
    this.bindMatchSchedule();
    this.bindCartype();
    // this.bindStatus();
    //  this.bindReson(this.appData.status_id);
    this.bindmediaVenue(this.$store.state.eventInfo.EventId);
  },

  methods: {

      makefulldilog: function () {
      this.fullscreen1 = !this.fullscreen1;
      if( this.fullscreen1==true){
        this.maxiconname='mdi-fullscreen-exit';
      }
      else{
      this.maxiconname='mdi-fullscreen';
      }
    },

btnvieweditorletter: function () {
    //  alert(this.appData.editorLetter.substring(this.appData.editorLetter.length-3));
    this.fullscreen1=false;
      this.dialogeditor = true;
    },

    bindPastevent: async function () {
      this.loadingstatus = true;
      await this.$axios
        .get("Media/pastEvents")
        .then((res) => {
          this.Pastevent = res.data.result;
        })
        .catch()
        .finally();
    },
    btncrop: function () {
        this.$refs.cropper="";
      //this.$refs.cropper("destroy");

      //this.picpathbackup="";

      this.editpath = this.$apiPath + this.appData.photo;
      // alert(this.editpath);
      // this.$refs.cropper.relativeZoom();
      this.modelPhoto = true;
    },

    close: function () {
      // this.$refs.cropper="";
      //this.editpath="";
      //this.editpath=this.$apiPath + this.appData.photo;
      //alert(this.editpath);
      // this.$refs.cropper.destroy();
      //this.$refs.cropper.data('cropper',null);
      //  this.editpath="";
      //this.picpathbackup="";
      this.modelPhoto = false;
    },

    bindMatchSchedule: async function () {
      this.loadingstatus = true;
      await this.$axios
        .get("Media/matchSchedule/" + this.$store.state.eventInfo.EventId)
        .then((res) => {
          this.matchSchedule = res.data.result;
        })
        .catch()
        .finally();
    },

    bindStatus: async function () {
      await this.$axios
        .get("Status")
        .then((res) => {
          this.StatusForUpdate = res.data.result;
        });
    },

    bindmediaVenue: async function (eventid) {
      await this.$axios
        .get("Media/mediaVenue/" + eventid)
        .then((res) => {
          console.log(res.data.result);
          this.MediaVenueForUpdate = res.data.result;
        });
    },

    // bindCartype: async function () {
    //   this.CardTypeForUpdate = [];
    //   await this.$axios
    //     .get("Media/mediaCategory")
    //     .then((res) => {
    //       this.CardTypeForUpdate = res.data.result;
    //     });
    // },


       bindCartype: async function () {
      this.CardTypeForUpdate = [];
      await this.$axios.get("Cards/GetByVenue/" + this.$store.state.eventInfo.EventId + "/media").then((res) => {
        this.CardTypeForUpdate = res.data.result;
      });
    },

    btnupdate: async function () {
      this.overlay=true;
      //alert(this.appData.status_id);
      //alert(this.appData.status_id + "/reasonid-" + this.appData.reason_id + "/cardid-" + this.appData.card_id);
      if (this.appData.status_id == "2") {
        this.showSnackbar("error", "Select Status !");
        return;
      }

      if (this.appData.status_id == "3") {
        if (this.appData.mediaType == "0") {
          this.showSnackbar("error", "Select Card !");
          return;
        }
      }

      if (this.appData.status_id != "3") {
        if (this.appData.reason_id == "0") {
          this.showSnackbar("error", "Select Reason !");
          return;
        }
      }

      this.PostData.event_id = this.$store.state.eventInfo.EventId;
      this.PostData.status_id = this.appData.status_id;
      // this.PostData.reason_id= this.appData.reason_id;
      // this.PostData.card_id= this.appData.card_id

        this.PostData.card_id = this.appData.mediaType;
      if (this.appData.status_id == "3") {
        this.PostData.reason_id = "0";
      } else {
        this.PostData.reason_id = this.appData.reason_id;
      }

      this.PostData.selectedMatch = this.appData.selectedMatchs;
      this.PostData.preferedCity = this.appData.preferedCity;
      this.PostData.eaid=this.$store.state.eventInfo.EventAdminID;
      this.PostData.roleForEvent = this.appData.roleForEvent;

     // console.log(this.appData.application_id);
   // console.log("B");
     // console.log(this.PostData);
      

      await this.$axios
          .put(
              "Media/ChangeAppStatus/" +
              this.appData.application_id,
            this.PostData
          )
        .then((res) => {
          if (res.data.status == "Success") {
            // this.Reload(sessionStorage.EVENTID, sessionStorage.VENUEID);
            // this.Reload(this.$store.state.eventInfo.EventId,this.appstatus);

            this.showSnackbar("Success", "Data Updated !");
            this.overlay=false;
          }
        })
        .catch()
        .finally();
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    bindReson: async function (StatusID) {
      await this.$axios
        .get("Reason/GetByStatusID/" + this.$store.state.eventInfo.EventId + "/" + StatusID)
        .then((res) => {
          //  console.log(res.data.result);
          this.appreasonlist = res.data.result;
        });
    },

    Reload: async function (event_id, status) {
      this.loadingstatus = true;
      await this.$axios
        .get(
          "Media/GetByAppstatus/" + event_id + "/" + status
        )
        .then((res) => {
          console.log(res.data.result);
          this.appData = res.data.result;

          //   this.loadingstatus = false;
          //this.TDselected = [];
          // this.postBulkUpdate.application_id = [];
        })
        .catch()
        .finally();
    },

    getreson: function () {
      // alert(this.appData.status_id);
      this.bindReson(this.appData.status_id);
    },

    appsave: function () {
      // alert(this.appData.zone);
    },

    closeDialog: async function () {
      this.picpathbackup = this.$refs.cropper
        .getCroppedCanvas({
          width: 220,
          height: 240,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg", 1);
      this.picpath = this.$refs.cropper
        .getCroppedCanvas({
          width: 220,
          height: 240,
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg", 1);
      // alert(this.picpath.split(",")[1])
      // this.appData.picHeadShot = this.$store.state.imgHeadShot.split(",")[1];
      this.mediaphoto.event_id = this.$store.state.eventInfo.EventId;
      this.mediaphoto.application_id = this.appData.application_id;
      this.mediaphoto.firstName = this.appData.firstName;
      this.mediaphoto.photo = this.picpath.split(",")[1];
      console.log(this.mediaphoto);
      //console.log(this.$store.state.eventInfo.EventId  + "/" + this.appData.application_id + "/" + this.appData.firstName + "/" + this.picpath);
      await this.$axios
        .put("Media/UploadHead", this.mediaphoto)
        .then((res) => {
          if (res.data.status == "Success") {
            this.showSnackbar("Success", "Photo Croped!");
            //this.appData.photo= this.$refs.cropper.getCroppedCanvas({width:240,height:260,imageSmoothingQuality:"high"}).toDataURL();
            // this.$apiPath="";
            //  alert(this.picpathbackup);
            console.log(res.data.result);
            this.appData.photo = "";
            // this.$apiPath=this.$apiPath;
            // this.appData.photo=this.picpath.split(",")[1];
            this.appData.photo = res.data.result;

            // this.appData.photo= this.$refs.cropper.src;
          }
        })
        .catch()
        .finally();
      this.close();

      this.modelPhoto = false;
    },

    imageOpt: function (bIndex) {
      switch (bIndex) {
        case 0:
          this.$refs.cropper.relativeZoom(0.2);
          break;
        case 1:
          this.$refs.cropper.relativeZoom(-0.2);
          break;
        case 2:
          // const dom = this.$refs.cropper;
          // let scale = dom.getAttribute("data-scale");
          // scale = scale ? -scale : -1;
          // this.$refs.cropper.scaleX(scale);
          // dom.setAttribute("data-scale", scale);
          break;
        case 3:
          this.$refs.cropper.rotate(90);
          break;
        case 4:
          this.$refs.cropper.move(-10, 0);
          break;
        case 5:
          this.$refs.cropper.move(10, 0);
          break;
        case 6:
          this.$refs.cropper.move(0, -10);
          break;
        case 7:
          this.$refs.cropper.move(0, 10);
          break;
        case 8:
          this.$refs.cropper.reset();
      }
    },
  },
};
</script>

<style scoped>
.imgId {
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  cursor: pointer;
}
</style>